import React from "react"
import { graphql, Link } from "gatsby"
import LargeHeader from "../components/LargeHeader"

const NotFoundPage = ({ data, location }) => {

  const {image1} = data;
  return (
    <>
      <LargeHeader image={image1} alt="Thomas Zipner">
        <h1 className="giant">404: Shoot!</h1>
        <h3 className="primary">
        This page does not exist.
        </h3>
        <p>This was not supposed to happen! <span role="img" aria-label="Oops Emoji">🤗</span></p>
        <p><Link to="/" className="button">Go back home</Link></p>
       
      </LargeHeader>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    image1: file(relativePath: { eq: "404x.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1250) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
